<template>
    <Toast :baseZIndex="9999" />
    <!-- Add/Edit wTemplate starts here -->
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>{{ this.dialogHeader }} <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan"
                        v-if="totalRecords.length != 0">{{ this.templateName }}
                    </Chip>
                </h5>
                <div>
                    <Button label="Listing" class="p-button-sm p-mr-2" @click="goToListing" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left"
                        v-if="count != 0 && totalRecords.length != 0" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" v-if="count < totalRecords.length - 1 && totalRecords.length != 0" />
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="chatbotId">
                        ChatbotId
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="chatbotId" v-model="chatbotId" :options="chatbotList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>

                    <!-- <InputText id="chatbotId" class="p-text-capitalize" v-model.trim="chatbotId" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !chatbotId }" /> -->
                    <small class="p-invalid p-error" v-if="v$.chatbotId.$error">{{
                        v$.chatbotId.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="language">
                        Language
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="language" v-model="language" :options="languageList" optionLabel="label"
                        placeholder="Select ..." appendTo="body" @change="isAlreadyPresentTemplateName($event)">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.language.$error">{{
                        v$.language.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="templateName">
                        Template Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="templateName" v-model.trim="templateName"
                        @input="isAlreadyPresentTemplateName($event)" required="true" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !templateName }" />
                    <small class="p-invalid p-error" v-if="v$.templateName.$error">{{
                        v$.templateName.$errors[0].$message
                    }}</small>
                    <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg }}</small>

                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="shorttemplateName">
                        Template For
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="shorttemplateName" v-model="shorttemplateName" :options="shorttemplateNameList"
                        optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>

                    <!-- <InputText id="shorttemplateName" class="p-text-capitalize" v-model.trim="shorttemplateName"
                        required="true" maxlength="100" /> -->
                    <small class="p-invalid p-error" v-if="v$.shorttemplateName.$error">{{
                        v$.shorttemplateName.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="vendors_template_id">
                        Vendor Template Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vendors_template_id" v-model.trim="vendors_template_id" maxlength="20" />
                    <small class="p-invalid p-error" v-if="v$.vendors_template_id.$error">{{
                        v$.vendors_template_id.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="templateBody">
                        Template Body
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="templateBody" v-model="templateBody" :autoResize="true" rows="2" cols="30"
                        placeholder="enter template body" autofocus
                        :class="{ 'p-invalid': submitted && !templateBody }" />
                    <!-- <InputText id="templateBody" class="p-text-capitalize" v-model.trim="templateBody" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !templateBody }" /> -->
                    <small class="p-invalid p-error" v-if="v$.templateBody.$error">{{
                        v$.templateBody.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="template_variables">
                        Template Variables
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Textarea id="template_variables" :autoResize="true" rows="2" cols="30"
                        v-model.trim="template_variables" required="true" placeholder="enter template variables"
                        autofocus />
                    <!-- <small class="p-invalid p-error" v-if="v$.template_variables.$error">{{
                        v$.template_variables.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="template_reply_type">
                        Reply Type
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="template_reply_type" v-model="template_reply_type" :options="templatereplytypeList"
                        optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.template_reply_type.$error">{{
                        v$.template_reply_type.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-8">
                    <label for="next_template_name">
                        Next Template Name
                        <!-- <span class="p-i   nvalid p-error">*</span> -->
                    </label>
                    <Dropdown id="next_template_name" showClear filter v-model="next_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.next_template_name.$error">{{
                        v$.next_template_name.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Header status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="headerstatusavl" name="option" v-bind:value="1" v-model="headerstatus"
                            :checked="headerstatus == 1" />
                        <label for="headerstatusavl" class="p-mr-2">Available</label>
                        <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0" v-model="headerstatus"
                            :checked="headerstatus == 0" />
                        <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1">
                    <label for="header_file_type">
                        Header File Type
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="header_file_type" showClear v-model="header_file_type" :options="headerFileTypeList"
                        optionLabel="label" placeholder="Select ..." @change="clearuploadimage()" appendTo="body">
                    </Dropdown>
                    <div v-if="headerstatus == 1">
                        <small class="p-invalid p-error" v-if="v$.header_file_type.$error">{{
                            v$.header_file_type.$errors[0].$message
                        }}</small>
                    </div>
                </div>

                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1 && header_file_type">
                    <label>Upload Header <span class="p-invalid p-error">*</span></label>
                    <div v-if="header_file_type.value == 1">
                        <FileUpload v-model="header_file_url" mode="basic" ref="header_file_url" :maxFileSize="1000000"
                            accept="image/*" chooseLabel="Upload Header Image" auto @select="onSelectProfile">
                        </FileUpload>
                        <span v-if="header_file_url_edit && profileUrl == null">
                            <img class="p-pt-2" role="presentation" alt="edit" :src="header_file_url_edit" width="120"
                                height="120" />
                        </span>
                    </div>
                    <div v-if="header_file_type.value == 2">
                        <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima" accept="pdf/*"
                            v-model="header_file_url" chooseLabel="Upload Header Document"
                            v-on:change="onSelectProfile()" />
                    </div>
                    <div v-if="header_file_type.value == 3">
                        <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima" accept="mp4/*"
                            v-model="header_file_url" chooseLabel="Upload Header Video"
                            v-on:change="onSelectProfile()" />
                    </div>
                    <div v-if="header_file_type.value == 4">
                        <FileUpload v-model="header_file_url" mode="basic" ref="tuneuploadeditref"
                            :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                            @select="onSelectProfile" />
                    </div>
                    <div v-if="header_file_type.value != 1">
                        <a v-if="header_file_url_edit != '' && header_file_url_edit != null" class="view-btn"
                            target="_blank" :href="header_file_url_edit">Preview</a>
                    </div>
                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                    <div v-if="headerstatus == 1 && header_file_type && profileImageError == ''">
                        <small class="p-invalid p-error" v-if="v$.header_file_url.$error">{{
                            v$.header_file_url.$errors[0].$message
                        }}</small>
                    </div>
                    <div>
                        <div v-if="header_file_type.value == 1">
                            <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name"
                                :src="profileUrl.url" width="120" height="120" />
                        </div>
                        <div v-if="header_file_type.value != 1">
                            <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
                                profileUrl.name
                            }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="first_button_name">
                        First Button Name
                    </label>
                    <InputText id="first_button_name" v-model.trim="first_button_name" required="true" maxlength="25" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="first_button_name">
                    <label for="first_button_template_name">
                        First Button Template Name

                        <span class="p-invalid p-error" v-if="first_button_name != ''">*</span>
                    </label>
                    <Dropdown id="first_button_template_name" showClear filter v-model="first_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="first_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.first_button_template_name.$error">{{
                            v$.first_button_template_name.$errors[0].$message
                        }}</small>
                    </div>
                </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="second_button_name">
                        Second Button Name
                    </label>
                    <InputText id="second_button_name" v-model.trim="second_button_name" maxlength="25" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="second_button_name">
                    <label for="second_button_template_name">
                        Second Button Template Name
                        <span class="p-invalid p-error" v-if="second_button_name != ''">*</span>

                    </label>
                    <Dropdown id="second_button_template_name" showClear filter v-model="second_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="second_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.second_button_template_name.$error">{{
                            v$.second_button_template_name.$errors[0].$message
                        }}</small>
                    </div>
                </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="third_button_name">
                        Third Button Name
                    </label>
                    <InputText id="third_button_name" v-model.trim="third_button_name" maxlength="25" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="third_button_name">
                    <label for="third_button_template_name">
                        Third Button Template Name
                        <span class="p-invalid p-error" v-if="third_button_name != ''">*</span>
                    </label>
                    <Dropdown id="third_button_template_name" showClear filter v-model="third_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="third_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.third_button_template_name.$error">{{
                            v$.third_button_template_name.$errors[0].$message
                            }}</small>
                    </div>
                </div>
            </div>
        </div>
        <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
            class="p-button-text p-ml-auto" @click="addNewWhatsAppTemplate" style="width: 90px;"
            :disabled="showLoader"></Button>
        <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
        <Divider type="dashed" />
        <!-- Template variables starts here -->
        <div>
            <span>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#shortname#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#name#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#dateofbirth#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#address#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#pincode#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#votersname#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voternumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#assemblyconstituency#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#assemblyconstituencynumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#pollingstation#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#partnumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#area#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#parliamentaryconstituency#]</Chip>
            </span>
        </div>
        <!-- Template variables ends here -->
    </div>
    <!-- Add/Edit wTemplate ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { inject } from 'vue';

export default {
    inject: ['global'],
    props: {
        propsData: {
            required: false,
        },
        propsData1: {
            required: true,
        },
        propsData2: {
            required: true,
        },
        propsData3: {
            required: true,
        },
        propsData4: {
            required: true,
        },
        propsData5: {
            required: true,
        },
    },
    emits: {
        go_to_previous: null,
    },
    data() {
        const globalData = inject('global');
        return {
            templateSettingList: [],
            templateEventList: [],
            v$: useValidate(),
            isDialogOpen: false,
            // isSubmitted: false,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: [],
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            // wTemplateError: "",
            dialogHeader: "Add WhatsApp Template",
            validation: {},
            // wTemplateId: '',
            profileImageError: '',
            languageList: [],
            shorttemplateNameList: [],
            templatereplytypeList: globalData.state.templateReplyTypeList,
            chatbotList: [],
            chatbot: '',
            submitted: false,
            templateName: '',
            templateAction: '',
            templateEvent: '',
            templateBody: '',
            template_variables: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',
            first_button_name: '',
            first_button_template_name: '',
            second_button_name: '',
            second_button_template_name: '',
            third_button_name: '',
            third_button_template_name: '',
            vendors_template_id: '',
            header_file_type: '',
            headerFileTypeList: globalData.state.headerFileTypeList,
            shorttemplateName: '',
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            chatbotId: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',

            isCopyWhatsappSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedWhatsAppSettings: [],
            client_name: '',
            localAdminName: '',
            count: 0,
        };
    },
    validations() {
        return {
            chatbotId: { required: helpers.withMessage('Please select chatbotId', required) },
            language: { required: helpers.withMessage('Please select language', required) },
            templateName: { required: helpers.withMessage('Please enter template name', required) },
            shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
            templateBody: { required: helpers.withMessage('Please enter template body', required) },
            vendors_template_id: { required: helpers.withMessage('Please enter vendor template Id', required) },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            header_file_type: {
                requiredIf: helpers.withMessage("Please select header file type",
                    requiredIf(
                        this.headerstatus == 1
                    )
                ),
            },
            header_file_url: {
                requiredIf: helpers.withMessage("Please upload header file",
                    requiredIf(
                        this.headerstatus == 1 && (this.header_file_url_edit == null || this.header_file_url_edit == '')
                    )
                ),
            },
            first_button_template_name: {
                requiredIf: helpers.withMessage("Please select First button template",
                    requiredIf(
                        this.first_button_name != ''
                    )
                ),
            },
            second_button_template_name: {
                requiredIf: helpers.withMessage("Please select Second button template",
                    requiredIf(
                        this.second_button_name != ''
                    )
                ),
            },
            third_button_template_name: {
                requiredIf: helpers.withMessage("Please select Third button template",
                    requiredIf(
                        this.third_button_name != ''
                    )
                ),
            },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.count = this.propsData5;
        this.totalRecords = this.propsData4;
        this.shorttemplateNameList = this.propsData1;
        this.chatbotList = this.propsData2;
        this.languageList = this.propsData3;
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        if (this.propsData != null) {
            this.onEditWhatsAppTemplateSettings(this.propsData);
        } else {
            this.getAllTemplateOnly();
            this.onAddWhatsAppTemplateSettings();
            this.totalRecords = []
        }
    },
    methods: {
        goToListing() {
            this.$emit('go_to_previous');
        },
        goToNext() {
            this.count++
            this.getRecordById(this.totalRecords[this.count]['am1']);
        },
        goToPrevious() {
            this.count--
            this.getRecordById(this.totalRecords[this.count]['am1']);
        },
        getRecordById(id) {
            let am1 = id;
            let vals = this.totalRecords.filter(function (item) {
                return item.am1 == am1;
            });
            if (vals.length > 0) {
                this.onEditWhatsAppTemplateSettings(vals[0]);
            }
        },
        getAllTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['am6'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'am3': templateEventList[i]['am3'],
                                    'am6': templateEventList[i]['am6'],
                                    'am34': templateEventList[i]['am34'],
                                });
                            }
                        }
                    }
                    console.log(templateEventList);
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },
        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },
        isAlreadyPresentTemplateName() {
            if (this.templateName != '') {
                if (this.templateName.length > 4) {
                    this.ApiService.isAlreadyPresentTemplateName({ uniqueName: this.templateName, rowId: this.row_id, clientId: this.routeParam, laguageId: this.language.value, laguageName: this.language.label }).then((data) => {
                        if (data.status == 200) {
                            this.foundUniqueIdErrorMsg = '';
                            // this.foundUniqueId = data.data;
                            if (data.count > 0)
                                this.foundUniqueIdErrorMsg = "This template name already exist";
                            this.loading = false;
                        } else {
                            this.foundUniqueIdErrorMsg = '';
                            this.loading = false;
                        }
                        this.loading = false;
                    });
                }
            }
        },

        async onSelectProfile(e) {
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
                this.profileUrl = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
                this.profileUrl = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                    this.header_file_url = this.file;
                }
            }
        },

        onAddWhatsAppTemplateSettings() {
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';
            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
        },

        onEditWhatsAppTemplateSettings(e) {
            this.row_id = e.am1;
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';
            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
            let result = e;
            this.ApiService.getAllTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['am6'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'am3': templateEventList[i]['am3'],
                                    'am6': templateEventList[i]['am6'],
                                    'am34': templateEventList[i]['am34'],
                                });
                            }
                        }
                    }
                    let templateEventnextbtn = this.templateEventList.filter(function (item) {
                        return item.value == result.am8;
                    });
                    if (templateEventnextbtn.length > 0) {
                        this.next_template_name = templateEventnextbtn[0];
                    }

                    let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                        return item.value == result.am10;
                    });
                    if (templateEventfirstbtn.length > 0) {
                        this.first_button_template_name = templateEventfirstbtn[0];
                    }

                    let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                        return item.value == result.am12;
                    });
                    if (templateEventSecondbtn.length > 0) {
                        this.second_button_template_name = templateEventSecondbtn[0];
                    }

                    let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                        return item.value == result.am14;
                    });
                    if (templateEventthirdbtn.length > 0) {
                        this.third_button_template_name = templateEventthirdbtn[0];
                    }
                } else {
                    this.templateEventList = '';
                }
            });
            if (result.am2 !== 0) {
                let chatbotListId = this.chatbotList.filter(function (item) {
                    return item.value == result.am2;
                });
                if (chatbotListId.length > 0) {
                    this.chatbotId = chatbotListId[0];
                }
            }
            this.templateName = result.am3;
            this.templateBody = result.am4;
            this.template_variables = result.am5;
            let languageListId = this.languageList.filter(function (item) {
                return item.value == result.am6;
            });
            if (languageListId.length > 0) {
                this.language = languageListId[0];
            }

            this.shorttemplateName = '';
            if (result.am35 !== 0) {
                let shorttemplateNameListId = this.shorttemplateNameList.filter(function (item) {
                    return item.value == result.am35;
                });
                if (shorttemplateNameListId.length > 0) {
                    this.shorttemplateName = shorttemplateNameListId[0];
                }
            }


            let template_reply_type_id = this.templatereplytypeList.filter(function (item) {
                return item.value == result.am7;
            });
            if (template_reply_type_id.length > 0) {
                this.template_reply_type = template_reply_type_id[0];
            }

            let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                return item.value == result.am33;
            });
            if (header_file_type_id.length > 0) {
                this.header_file_type = header_file_type_id[0];
            }


            this.next_template_name = result.am8;
            this.first_button_name = result.am9;
            this.second_button_name = result.am11;
            this.third_button_name = result.am13;
            this.vendors_template_id = result.am32;

            this.headerstatus = result.am15;
            if (result.am16 != null || result.am16 != '') {
                this.header_file_url_edit = result.am16;
            }

            this.dialogHeader = "Edit WhatsApp Template";
            this.isDialogOpen = true;
            this.submitted = false;
        },

        async addNewWhatsAppTemplate() {
            this.submitted = true;
            this.v$.$validate();

            let formData = new FormData();
            formData.append("am1", this.row_id);
            formData.append("am2", this.chatbotId.value);
            formData.append('am3', this.templateName);
            formData.append('am4', this.templateBody);
            formData.append('am5', this.template_variables);
            formData.append('am6', this.language.value);
            formData.append('am7', this.template_reply_type.value);
            if (this.next_template_name) {
                formData.append('am8', this.next_template_name.value);
            } else {
                formData.append('am8', '');
            }
            // formData.append('am8', this.next_template_name);
            formData.append('am9', this.first_button_name);
            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                formData.append('am10', this.first_button_template_name.value);
            } else {
                formData.append('am10', '');
            }
            formData.append('am11', this.second_button_name);
            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                formData.append('am12', this.second_button_template_name.value);
            } else {
                formData.append('am12', '');
            }
            formData.append('am13', this.third_button_name);
            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                formData.append('am14', this.third_button_template_name.value);
            }
            else {
                formData.append('am14', '');
            }
            formData.append('am15', this.headerstatus);
            if (this.headerstatus == 1) {
                if (this.header_file_url != null) {
                    formData.append('am16', this.header_file_url);
                    formData.append('am33', this.header_file_type.value);
                }
            } else {
                formData.append('am16', '');

                formData.append('am33', 0);
            }

            formData.append('am32', this.vendors_template_id);
            formData.append('am34', this.shorttemplateName.label);
            formData.append('am35', this.shorttemplateName.value);
            formData.append('clientId', this.routeParam);

            if (!this.v$.$error && this.foundUniqueIdErrorMsg == '') {
                this.showLoader = true;
                let result = await this.ApiService.addUpdateClientwhatsAppTemplates(formData)
                this.showLoader = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.wTemplateId = '';
                    this.isDialogOpen = false;
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                    if (this.row_id == 0) {
                        this.goToListing();
                    } else {
                        const element = this.totalRecords.find(e => e.am1 === this.row_id);
                        if (element) {
                            element.am2 = this.chatbotId.value;
                            element.am3 = this.templateName;
                            element.am4 = this.templateBody;
                            element.am5 = this.template_variables;
                            element.am6 = this.language.value;
                            element.am7 = this.template_reply_type.value;
                            if (this.next_template_name) {
                                element.am8 = this.next_template_name.value;
                            } else {
                                element.am8 = '';
                            }
                            element.am9 = this.first_button_name;
                            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                                element.am10 = this.first_button_template_name.value;
                            } else {
                                element.am10 = '';
                            }
                            element.am11 = this.second_button_name;
                            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                                element.am12 = this.second_button_template_name.value;
                            } else {
                                element.am12 = '';
                            }
                            element.am13 = this.third_button_name;
                            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                                element.am14 = this.third_button_template_name.value;
                            }
                            else {
                                element.am14 = '';
                            }
                            element.am15 = this.headerstatus;
                            if (this.headerstatus == 1) {
                                if (this.header_file_url != null) {
                                    element.am16 = this.header_file_url;
                                    element.am33 = this.header_file_type.value;
                                }
                            } else {
                                element.am16 = '';
                                element.am33 = 0;
                            }
                            element.am32 = this.vendors_template_id;
                            element.am34 = this.shorttemplateName.label;
                            element.am35 = this.shorttemplateName.value;
                        }
                    }
                } else {
                    this.isDialogOpen = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Whatsapp Template not added. Please try again', life: 3000 });
                }
            }
        },
    }

};
</script>

<style scoped></style>
