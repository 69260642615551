<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid" v-if="!addStatus && !editStatus">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5> WhatsApp Template Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable v-model:first="first" :value="templateSettingList"
                                v-model:selection="selectedProduct" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="am1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:left>
                                        <h5 class="p-m-0">WhatsApp Template Settings</h5>
                                    </template>
                                    <template v-slot:right>
                                        <Button label="Copy to other client  " icon="pi pi-copy" class="p-mr-2"
                                            @click="onSelectWhatsappSettings" style="margin: right 3.5rem;" />

                                        <Button label="Add whatsApp Template" icon="pi pi-plus"
                                            @click="onAddWhatsAppTemplateSetting" style="margin: right 3.5rem;"
                                            class="p-mr-2" />

                                        <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                            @click="filterlisting()" class="p-mr-2"
                                            style="margin: right 3.5rem; width: 160px;"></Button>
                                        <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash"
                                            class="p-button-success p-mr-2" @click="filterlisting()"
                                            style="margin: right 3.5rem; width: 160px;"></Button>
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>

                                <Column selectionMode="multiple" headerStyle="width: 4%"></Column>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column field="name" header="Template Name" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text"> {{ data.am3 }}</div>
                                    </template>
                                </Column>
                                <Column field="body" header="Template For" headerStyle="width: 25%">
                                    <template #body="{ data }">
                                        <!-- <div class="p-text-capitalize"> {{ data.am34 }}</div> -->
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.am34 }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Langauge" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.am6 != '' && data.am6 != null"> {{ searchLanguage(data.am6)
                                                }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Vendor Id" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">{{ data.am32 }}</div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data, index }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditWhatsAppTemplateSettings(data, index)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit wTemplate Dialog starts here -->
    <AddEditWhatsAppTemplateSettings :propsData=editableData :propsData1=shorttemplateNameList :propsData2="chatbotList"
        :propsData3="languageList" :propsData4=templateSettingList :propsData5=index @go_to_previous="handleButtonClick" v-if="addStatus || editStatus" />
    <!-- Add/Edit wTemplate Dialog ends here -->

    <!-- Copy Settings dialog start here -->
    <Dialog v-model:visible="isCopyWhatsappSetting" :style="{ width: '600px' }" header="Copy WhatsApp Settings"
        :modal="true" class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="client_name">
                Select Client
            </label>
            <Dropdown v-model="client_name" :options="clientList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select client" />
        </div>
        <div class="p-field p-col-12 p-md-9" v-if="selectedProduct == '' || selectedProduct == null">
            <label for="chatbot">
                Select chatbot
            </label>
            <Dropdown v-model="chatbot" :options="chatbotList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select chatbot" />

        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="client_name == null || client_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Copy Settings dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <label for="chatbotId">
                        ChatbotId
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="chatbotId" v-model="filter_chatbotId" :options="chatbotList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="language">
                        Language
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="language" v-model="filter_language" :options="languageList" optionLabel="label"
                        placeholder="Select ..." appendTo="body">
                    </Dropdown>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="next_template_name">
                        Template Name
                    </label>
                    <Dropdown id="next_template_name" showClear filter v-model="filter_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(filter_chatbotId, filter_language, filter_template_name)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>

<script>
import ApiService from '../../service/ApiService';
import { useRoute } from 'vue-router';
import router from '@/router';
import AddEditWhatsAppTemplateSettings from './AddEditWhatsAppTemplateSettings.vue';

export default {
    components: {
        AddEditWhatsAppTemplateSettings
    },
    data() {
        return {
            templateSettingList: [],
            templateEventList: [],
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            languageList: [],
            shorttemplateNameList: [],
            chatbotList: [],
            chatbot: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',
            isCopyWhatsappSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedWhatsAppSettings: [],
            client_name: '',
            localAdminName: '',
            addStatus: false,
            editStatus: false,
            editableData: null,
            filter_template_name: '',
            filter_chatbotId: '',
            filter_language: '',
            filterDialog: false,
            showFillFilter: false,
            index: 0,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        this.getWhatsAppTemplateSettings({ page_no: this.page_no, clientId: this.routeParam, chatbotId: this.filter_chatbotId, language: this.filter_language, templateName: this.filter_template_name.am3 });
        this.getTemplateDescriptions();
        this.getchatbotList();
        this.getLanguages();
        this.getAllTemplateOnly();
    },
    methods: {
        handleButtonClick() {
            this.addStatus = false;
            this.editStatus = false;
            this.editableData = null;
            this.getWhatsAppTemplateSettings({ page_no: this.page_no, clientId: this.routeParam, chatbotId: this.filter_chatbotId, language: this.filter_language, templateName: this.filter_template_name.am3 })
        },

        searchLanguage(value) {
            let vals = this.languageList.filter(function (item) {
                return item.value == value;
            });
            if (vals.length > 0) {
                return vals[0]['label']
            }
        },

        getLanguages() {
            this.ApiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },

        getAllTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['am6'];
                        });
                        if (vals.length > 0) {
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'],
                                    'am3': templateEventList[i]['am3'],
                                    'am6': templateEventList[i]['am6'],
                                    'am34': templateEventList[i]['am34'],
                                });
                            }
                        }
                    }
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        onSelectWhatsappSettings() {
            this.ApiService.getClientListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });

            if (this.selectedProduct == '' || this.selectedProduct == null) {
                this.getchatbotList();
            }
            this.isCopyWhatsappSetting = true;
        },

        getchatbotList() {
            this.ApiService.getchatbotList().then((data) => {
                if (data.status == 200) {
                    this.chatbotList = data.data;
                } else {
                    this.chatbotList = null;
                }
            });
        },

        onCopySettingSubmit() {
            this.copysettingloader = true;
            var formData = new FormData();
            if (this.selectedProduct) {
                let vals = this.selectedProduct.map(function (item) {
                    return item;
                });
                this.selectedWhatsAppSettings = vals;
                formData.append("selectedwhatsAppSetting", JSON.stringify(this.selectedWhatsAppSettings));
            }
            // console.log(JSON.stringify(this.selectedWhatsAppSettings));

            formData.append("clientId", this.routeParam);
            formData.append("CopyIntoClientId", this.client_name.value);
            // if (this.selectedWhatsAppSettings)

            if (this.chatbot)
                formData.append("chatbotId", this.chatbot.value);

            //  for (var pair of formData.entries()) {
            //      console.log(pair[0] + ' - ' + pair[1]);
            //  }
            this.ApiService.copywhatsAppSettingToOtherClient(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getWhatsAppTemplateSettings({ clientId: this.routeParam, page_no: this.page_no });
                    this.copysettingloader = false;
                    this.isCopyWhatsappSetting = false;
                    this.client_name = "";
                    this.chatbot = "";
                    this.selectedWhatsAppSettings = [];
                    this.selectedProduct = null;
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },

        getTemplateDescriptions() {
            this.loading = true;
            this.ApiService.getTemplateDescriptions().then((data) => {
                if (data.status == 200) {
                    this.shorttemplateNameList = data.data;
                } else {
                    this.shorttemplateNameList = '';
                }
                this.loading = false;
            });
        },

        onAddWhatsAppTemplateSetting() {
            this.addStatus = true;
            this.editStatus = false;
        },

        onEditWhatsAppTemplateSettings(e, index) {
            this.addStatus = false;
            this.editStatus = true;
            this.editableData = e;
            this.index = index;
        },

        async getWhatsAppTemplateSettings(params) {
            try {
                const result = await this.ApiService.getWhatsAppTemplateSettings({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.templateSettingList = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },
            });
        },

        filterlisting() {
            this.filterDialog = true;
        },

        getFilteredData(filter_chatbotId, filter_language, filter_template_name) {
            this.loading = true;
            this.filter_chatbotId = filter_chatbotId;
            let chatbotId = null;
            let language = null;
            let template_name = null;
            if (filter_chatbotId) {
                chatbotId = filter_chatbotId.value
            }
            this.filter_language = filter_language;
            if (filter_language) {
                language = filter_language.value;
            }
            this.filter_template_name = filter_template_name;
            if (filter_template_name) {
                template_name = filter_template_name.am3;
            }
            this.getWhatsAppTemplateSettings({
                chatbotId: chatbotId, language: language, templateName: template_name, clientId: this.routeParam
            });
            this.showFillFilter = true;
            this.filterDialog = false;
        },

        reSetFilteredData() {
            this.filter_template_name = "";
            this.filter_chatbotId = '';
            this.filter_language = '';
            this.getWhatsAppTemplateSettings({ clientId: this.routeParam });
            this.showFillFilter = false;
            this.filterDialog = false;
        },

        goToPrevious() {
            router.push({
                name: "template-actions",
                params: { adminSubId: this.routeParam },

            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getWhatsAppTemplateSettings({ page_no: event.page, clientId: this.routeParam, chatbotId: this.filter_chatbotId, language: this.filter_language, templateName: this.filter_template_name.am3 });
        },
        goToNext() {
            router.push({
                name: "web-bot-template-settings",
                params: { adminSubId: this.routeParam },
            });
        }
    }

};
</script>

<style scoped></style>
